<template>
  <div class="header">
      <div class="header_icon">
        <img  src="../assets/image/321.png" alt="" style="align-self: center;">
        <h2 style="color:#409EFF">扁鹊问诊管理平台</h2>
      </div>
      <div>
        <el-dropdown trigger="click"  @command="handleCommand" style="margin-right: 30px">
        
          <div style="display:flex;align-items: center;">
            <img src="../assets/feiImg/16.png" alt="">
            &nbsp;&nbsp;&nbsp;
            <div>管理员</div>
          </div>
          <el-dropdown-menu  slot="dropdown">
            <el-dropdown-item  icon="el-icon-s-tools" :command="composeValue('edit')">编辑信息</el-dropdown-item>
            <el-dropdown-item icon="el-icon-key" :command="composeValue('password')" divided>修改密码</el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" :command="composeValue('LogOut')" divided>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <img @click="logout()" src="../assets/feiImg/15.png" alt="" style="cursor:pointer;">
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginname:'',
      state:0
    };
  },
  mounted() {
    this.loginname = JSON.parse(localStorage.getItem('user')).name
   },
  methods:{
    changeuser(){},
    logout(){
        this.$confirm('您将要退出账号, 是否继续?', '退出', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.$router.push({path:`/${localStorage.getItem('login_role') ? `?role=${localStorage.getItem('login_role')}` : ''}`});
            
            localStorage.clear();
            this.$notify({
                type: 'success',
                message: '您已退出登录！'
            });
        }).catch(() => {
        });
    },
    handleCommand(command){
      if(command.command == 'LogOut'){
        this.logout();
      }else if(command.command == 'edit'){
        this.$router.push({path:'/UserInfo'});
      }else if(command.command == 'password'){
        this.$router.push({path:'/Password'});
      }
    },
    composeValue(command){
      return {
          command:command
      }
    }
  }
};
</script>
<style lang="less" scoped>
.header{
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  margin: 0 auto;
  //height: 119px;
  //text-align: right;
  background: #fff !important;
  box-shadow: 0 12px 40px 0 rgba(148,186,215,0.2);
  flex-shrink: 0;
  padding: 0 0.15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}
.header_icon{
  display: flex;
  align-items: center;
}
.header_icon img{
  width: 1rem;
}
</style>