<template>
    <div class="bbb">
      <Header/>
      <div id="Interrogation" class="content time_slot">
        <leftNavigationBar/>
        <div class="content_">
          <div>
            <div class="search">
            <div>
              <el-button class="refresh" @click="loadList" icon="el-icon-refresh"></el-button>
            </div>
            <div class="roundInp">
                <el-input
                    placeholder="请输入内容"
                    v-on:input="search"
                    v-model="input">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
          </div>
          </div>
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="全部" name="all"></el-tab-pane>
              <el-tab-pane label="待新增" name="1"></el-tab-pane>
              <el-tab-pane label="待核对" name="2"></el-tab-pane>
            </el-tabs>
          </div>
          <div>
            <el-table
                :data="tableData"
            >
              <el-table-column
                  prop="mulv"
                  label="目录"
              >
              </el-table-column>
              <el-table-column
                  prop="bianma"
                  label="商品编码"
              >
              </el-table-column>
              <el-table-column
                  prop="yaocai"
                  label="通用名/药材名"
              >
              </el-table-column>
              <el-table-column
                  prop="shangping"
                  label="商品名/中药别名"
              >
              </el-table-column>
              <el-table-column
                  prop="suoshu"
                  label="所属目录"
              >
              </el-table-column>
              <el-table-column
                  prop="pihao"
                  label="批准文号"
              >
              </el-table-column>
              <el-table-column
                  prop="guige"
                  label="包装规格"
              >
              </el-table-column>
              <el-table-column
                  prop="baozhuang"
                  label="最大包装"
              >
              </el-table-column>
              <el-table-column
                  prop="jiage"
                  label="价格"
              >
              </el-table-column>
              <el-table-column
                  prop="chanpin"
                  label="产品状态"
              >
              </el-table-column>
              <el-table-column
                  prop="state"
                  label="核对状态"
              >
              </el-table-column>
              <el-table-column
                  label="操作"
              >
                <template slot-scope="scope">
                    <el-button @click="chakan(scope.row)" type="text" slot="reference">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
        <el-dialog
        :title="title"
        :visible.sync="drawer"
        width="50%"
        direction="rtl"
        :before-close="handleClose">
        <el-form ref="form" :model="form" label-position="top" label-width="100px">
            <el-form-item label="目录">
            <el-input v-model="form.mulv" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item label="商品类别">
            <el-select v-model="form.leibie" placeholder="请选择">
                    <el-option
                    v-for="item in leibie_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择平台品种匹配">
            <el-select v-model="form.pintzhong" placeholder="请选择">
                    <el-option
                    v-for="item in pintzhong_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
            <h3>默认参数 （匹配品种后可编辑，添加品种可录入）</h3>
            </el-form-item>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="通用名">
                        <el-input v-model="form.yaocai" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="商品名">
                        <el-input v-model="form.shangping" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="最小包装含量">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="最小包装含量单位">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="大小包装转换比">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="最小包装单位">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="最大包装单位">
                        <el-input v-model="form.baozhuang" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="默认用药剂量">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="默认给药方式">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="默认用药频次">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="税收分类编码">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="税率%">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="零税率标识">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="图片">
                        <el-upload
                        action="https://jsonplaceholder.typicode.com/posts/"
                        list-type="picture-card"
                        >
                        <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <h3>销售参数（匹配品种后可编辑，添加品种可录入）</h3>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="药店商品编码">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="平台商品编码">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="上下架状态">
                        <el-radio  label="1">上架</el-radio>
                        <el-radio label="2">下架</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="库存状态">
                        <el-radio  label="1">有货</el-radio>
                        <el-radio  label="2">缺货</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="价格（元）">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="调价范围（元）">
                        <div class="tianjiaclass">
                            <el-input  placeholder="请输入内容"></el-input> 
                            ——
                             <el-input  placeholder="请输入内容"></el-input>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="医院ID">
                        <el-input  placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="医院商品his码">
                        <el-input  placeholder="请输入内容"></el-input> 
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
      </el-dialog>
      </div>
    </div>
  </template>
  <script>
  import leftNavigationBar from './components/leftNavigationBar.vue'
  import Header from '../components/header.vue'
  import mixin from "@/utils/mixin";
  
  export default {
    mixins: [mixin],
    components: {
      leftNavigationBar,
      Header,
    },
    data() {
      return {
        tableData: [],
        form:{},
        leibie_options:[
            {label:'药品',value:'药品'}
        ],
        pintzhong_options:[
            
        ]
      }
    },
    methods: {
      //创建
      establish() {  
      },
      chakan(item){
        this.form = item
        this.title = '查看'
        this.drawer = true
        this.pintzhong_options = [
            {
                label:`${item.yaocai} ${item.guige} ${item.suoshu} ${item.pihao}`,
                value:`${item.yaocai} ${item.guige} ${item.suoshu} ${item.pihao}`
            }
        ]
        this.form.leibie = '药品'
        this.form.pintzhong = `${item.yaocai} ${item.guige} ${item.suoshu} ${item.pihao}`;
        
      },
      loadList() {
        this.tableData = [
            {
                mulv:'大同市新建华佗药房医药有限公司',
                bianma:'YTD_444_0200',
                yaocai:'拉米夫定片',
                shangping:'拉米夫定片（健甘灵）',
                suoshu:'上海美优制药有限公司',
                pihao:'国药准字H20050586',
                guige:'0.1g*14片',
                baozhuang:'盒',
                jiage:'71.5',
                chanpin:'上架，有货',
                state:'已核对',
                leibie:'药品'
            },
        ]
      },
    },
    created() {
      this.loadList();
    }
  }
  </script>
  <style scopde lang="less">
    .tianjiaclass{
        display: flex;
    }
  
  </style>