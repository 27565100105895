<template>
  <div class="bbb">
      <van-nav-bar
      :title="state == 'Drugs' ? '选择历史用药' : '当前用药'"
      left-arrow
      @click-left="onClickLeft"
      />
    <div id="Interrogation" class=" time_slot yaopinxuanzhe">

      <div class="container ">
        <div class="Drugs_top">
          <el-input placeholder="药品名、首字母" @input="debounce(1000)" v-model="search_value">
            <template slot="append">
              <el-button icon="el-icon-full-screen"  @click="triggerFileInput">扫码</el-button>
            </template>
          </el-input>
          <van-dropdown-menu class="margin_top" v-if="historyTableData.length !==0 && search_value !==''"  active-color="#409EFF">
            <van-dropdown-item title="按规格筛选" @change="changeSpecs('specsValue')"  v-model="specsValue" :options="specss" />
            <van-dropdown-item title="按厂商筛选" @change="changeSpecs('manufacturingEnterpriseValue')"  v-model="manufacturingEnterpriseValue" :options="manufacturingEnterprise" />
          </van-dropdown-menu>
          <div v-if="headershow">
            {{LastUsedDrugs.length == 0 ? '' : '可能的药品：'}}
            <el-tag
              class="margin_top"
              v-for="tag in LastUsedDrugs"
              :key="tag.id"
              @click="DrugsCli(tag)"
              >
              {{tag.name}}
            </el-tag>
          </div>
          <div>
              <barCode v-if="barCode_show"></barCode>
          </div>
          <div  class="margin_top"  v-if="drugSelected.length == 0 && tableData.length !==0 && headershow">
              <el-alert
                title="请从下表中选择药品"
                type="warning"
                center
                :closable="false"
                show-icon>
              </el-alert>
          </div>
        </div>
        <div class="Drugs_middle">
          <div class=" margin_top" style="margin-bottom: 100px;"v-if="tableData.length !== 0" >
            <div v-for="(item,index) of tableData" class="bpd " :class="index !==0 ? 'margin_top' : ''">
              <div style="display: flex;" >
                <div style="width:100px" @click="drugs_details(item.id)">
                  <van-image v-if="hasParameters(item.imgurl)" width="100" height="100" :src="item.imgurl" />
                  <img v-else style="width:90px;height: 80px ;" src="../assets/image/Drugs.webp" alt="">
                </div>
                <div style="width: 0; flex-grow: 1;">
                  <div style="font-size: 16px; padding:1px 5px;">
                    <span style="border-bottom: 2px solid #f56c6c;">{{item.name}} </span>
                    <!-- 医保分类（{{item.PublicDrugs[0].class_ab}}） -->
                  </div>
                  <div  style="font-size: 12px; padding:1px 5px;">
                    规格：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].specs : '' }} 
                  </div>
                  <div  style="font-size: 12px; padding:1px 5px;">
                    生产企业：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].manufacturingEnterprise : '' }} 
                  </div>
                  <div  style="font-size: 12px; padding:1px 5px;">
                    国药准字：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].approvalNumber : '' }} 
                  </div>
                  <div  style="font-size: 12px; padding:1px 5px;">
                    医保编码：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].drugcode : '' }} 
                  </div>
                  <div class="price_number">
                    <span  class="margin-left4 price">
                      <span v-if="state == 'Drugs'">
                      </span>
                    </span>
                    <div @click.stop>
                      <el-input-number v-model="item.number"  :min="0" :max="item.maxNumber*1" @change="number_change(item,index)"  style="width: 150px;"></el-input-number>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <van-action-sheet v-model="show" title="已选择药品">
              <div v-for="(item,index) of drugSelected" class="bpd margin_top" >
              <div style="display: flex;" @click="drugs_details(item.id)">
                <div style="width:100px">
                  <van-image v-if="hasParameters(item.imgurl)" width="100" height="100" :src="item.imgurl" />
                  <img v-else style="width:90px;height: 80px ;" src="../assets/image/Drugs.webp" alt="">
                </div>
                <div style="width: 0; flex-grow: 1;">
                  <div style="font-size: 16px; padding:1px 5px;">
                    {{item.name}}
                  </div>
                  <div  style="font-size: 16px; padding:1px 5px;">
                    规格：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].specs : '' }}
                  </div>
                  <div  style="font-size: 12px; padding:1px 5px;">
                    生产企业：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].manufacturingEnterprise : '' }} 
                  </div>
                  <div  style="font-size: 12px; padding:1px 5px;">
                    国药准字：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].approvalNumber : '' }} 
                  </div>
                  <div  style="font-size: 12px; padding:1px 5px;">
                    医保编码：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].drugcode : '' }} 
                  </div>
                  <div class="price_number">
                    <span v-if="item.price !== '0'" class="margin-left4 price">
                    </span>
                    <div @click.stop style="padding:0 10px;">
                      <van-stepper input-width="20px" button-size="20px" v-model="item.number"  min="0"   :max="item.maxNumber"/>
                      <van-button type="danger" @click="drugsdefault(item,index)" size="mini">删除</van-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </van-action-sheet>
          </div>
          <div  v-else>
            <van-nodata   :figure="imgurl" title="暂无药品信息" />
          </div>
        </div>
        <div class="Drugs_bottom">
          <div class="van-submit-bar__wrapper" >
            <div class="van-submit-bar van-submit-bar--safe-area-inset-bottom van-hairline--top" style="padding: 0 5px;">
              <div v-for="tag in drugSelected" style="display: inline-block;">
                <el-tag
                    v-if="tag.number>0"
                  :key="tag.id"
                  >
                  {{tag.name}} × <span class="label">{{ tag.number }}</span>
                </el-tag>
              </div>
                
              <div class="van-submit-bar__bar">
                <div class="van-submit-bar__text">
                </div>
                <div>
                  <span  v-if="state == 'Drugs'" class="van-submit-bar__wrapper_text">具体价格以实际结算为准</span>
                  <van-button @click="onClickLeft" type="warning" block>选择完毕</van-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      
      <el-button v-if="state == 'Drugs'" class="ShoppingCart" @click="drugsShow" icon="el-icon-goods" circle></el-button>
      
    </div>
  </div>
</template>
<script>
import footer_ from '../components/footer.vue'
import base from './api/base.js'
import { Loading } from 'element-ui';
import mixin from "@/utils/mixin";
import barCode from './components/barCode.vue'
export default {
  mixins: [mixin],
  components: {
    footer_,
    barCode
  },
  data() {
    return {
      value: 0,
      specss: [
       
      ],
      manufacturingEnterprise:[],
      manufacturingEnterpriseValue:'',
      specsValue:'',
      tableData:[],
      search_value:'',
      show:false,
      headershow:true,
      disease:[],
      barCode_show:false,
      state:'',
      drugsList:[],
      number:0,
      imgurl:require('../assets/image/nodepartment.png'),
      LastUsedDrugs:[],
      drugSelected:[],
      historyTableData:[]
    }
  },
  methods: {
    triggerFileInput() {
        this.barCode_show = true
    },
    barCode_show_fun(){
      this.barCode_show = false
    },
    maxNumber(item,number,index){
      if(number > item.maxNumber ){
        this.$message.error('当前药品数量超过最大值');
        this.tableData[index].number = item.maxNumber
      }
      return item.maxNumber+1 
    },
    removeZeroValues(array){
      const newArray = array.filter(value => value.number > 0);
      return newArray;
    },
    tableDataHeight(){
      let height = '70vh'
      if(this.search_value !=='' && this.search_value.length>3){
        height = '52vh'
      }
      return height
    },
    number_change(item,index){
      let yxKey = {
        NewYXHospital:'new_yx_id',
        LodYXHospital:'yx_id',
      }
      if(item.PublicDrugs[0][yxKey[localStorage.getItem('ih_type')]].length<5){
        this.$message.error('当前药品不可选用，请联系管理员添加');
        this.$nextTick(() => {
          item.number = 0
        });
        return 
      }
      let drugSelected = this.drugSelected
      let drugSelectedIndex =  this.drugSelected.findIndex(element=>{{
        return element.id == item.id
      }})
      if(drugSelectedIndex !==-1){
        this.drugSelected[drugSelectedIndex].number = item.number
      }else{
        if(item.PublicDrugs[0].disable.length >5 || item.disable.length >5){
          this.$message.error(item.PublicDrugs[0].disable.length>5 ? item.PublicDrugs[0].disable : item.disable);
          this.$nextTick(() => {
            item.number = 0
          });
          return
        }
        if(drugSelected.length >=5){
          this.$message.error('最多可选择5种药品');
          this.$nextTick(() => {
            item.number = 0
          });
          return
        }
        let approvalNumberFlse = this.drugSelected.filter(item=>{
          return item.PublicDrugs[0].approvalNumber.slice(0,5) == '国药准字Z' || item.PublicDrugs[0].approvalNumber.slice(0,1) == 'Z'
        })
        if(approvalNumberFlse.length >=2 && (item.PublicDrugs[0].approvalNumber.slice(0,5) == '国药准字Z' || item.PublicDrugs[0].approvalNumber.slice(0,1) == 'Z')){
          this.$message.error('最多可选择两种中成药');
                 this.$nextTick(() => {
                  item.number = 0
                });
          return
        }
        drugSelected.push(item)
      }
      this.drugSelected =  this.removeZeroValues(this.drugSelected)
      sessionStorage.setItem('drugSelected', JSON.stringify(this.drugSelected))
     

   
    },
    drugsdefault(item,index){
      this.drugSelected.splice(index,1)
      this.drugSelected.forEach(element => {
        if(element.id == item.id){
          element.number = '0'
        }
      });
      sessionStorage.setItem('drugSelected', JSON.stringify(this.drugSelected))
      
    },
    drugsShow(){
      this.show = true;
      let Drugs = []
      this.tableData.forEach(element => {
        if(element.number !== '0' && element.number){
          Drugs.push(element)
        }
      });
      
      this.drugsList = Drugs
    },
    onClickLeft(){
      this.barCode_show = false
      let Drugs = []
      let Drugs_name = []
      this.drugSelected.forEach(element => {
        if(element.number !== '0' && element.number){
          element.imgurl = ''
          Drugs.push(element)
          Drugs_name.push(`${element.name}x ${element.number}`)
        }
      });
      if(Drugs_name.length !==0){
        const h = this.$createElement;
        this.$msgbox({
          title: '提醒',
          message: h('p', { style: 'text-align: center' }, [
              h('span', null, '您当前选择药品为'),
              h('i', { style: 'font-weight: 800' },Drugs_name.join('， ')),
    
          ]),
          showCancelButton: true,
          confirmButtonText: '选择完毕',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            this.onClickButton()
            this.$router.back(-1)
          }
        })
      }else{
        const h = this.$createElement;
        this.$msgbox({
          title: '提醒',
          message: h('p', { style: 'text-align: center' }, [
              h('span', null, '您当前暂未选择药品'),
    
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          beforeClose: (action, instance, done) => {
            if(action == 'confirm'){
                this.onClickButton()
                this.$router.back(-1)
                // this.$router.back(-1)
              }
          }
        })
      }
      

    },
    onClickButton(){
      let Drugs = []
      this.drugSelected.forEach(element => {
        if(element.number !== '0' && element.number){
          element.imgurl = ''
          Drugs.push(element)
        }
      });
      console.log(this.drugSelected)
      if(this.$route.query.type == 'current'){
        sessionStorage.setItem('Current', JSON.stringify(Drugs))
      }else{
        sessionStorage.setItem('Drugs', JSON.stringify(Drugs))
        sessionStorage.setItem('drugSelected', JSON.stringify(this.drugSelected))
      }
    },
    DrugsCli(item){
      this.search_value = item.name
      this.loadList()
    },
    barCode(Code){
      this.search_value = Code
      this.loadList()
    },
    async loadList() {
      if(this.search_value !=='' && this.search_value.length<3){
        return
      }
      try {
        let params = {
          search_db: 'Drugs',
          query_type: `limit|0-${this.search_value == '' ? 5 : 150}`,
          no_count:'1'
        };
        let key = 'summary'
        if(this.search_value.length == 13){
          key = 'barCode'
        }
        let data = {
          query: [
            {
              fild: { [key]: this.search_value == '' ? undefined : this.search_value },
              type: this.search_value == '' ? '=' : '%',
              condition: '&&',
            },
            {
              fild: { pharmacy_id: this.return_drugs_id() },
              type: '=',
              condition: '&&',
            }
          ],
        };

        const drugsRes = await this.$api.article.query2(params, data);

        const drugsPromises = drugsRes.data.result.map(async (element) => {

          element['number'] = '0';
          if (element.maxNumber == 'None') {
            element.maxNumber = '10';
          }
          
          if(sessionStorage.getItem('drugSelected')){
            this.drugSelected = this.removeZeroValues(JSON.parse(sessionStorage.getItem('drugSelected')))
            console.log(this.drugSelected)
            this.drugSelected.forEach(item => {
              if(element.id == item.id){
                element.number = item.number
              }
            });
          }
          let PublicDrugsParams = {
            search_db: 'PublicDrugs',
            query_type: 'equal',
            no_count:'1'
          };

          let PublicDrugsData = {
            query: [
              {
                fild: { id: element.publicdrugs_id },
                type: '=',
                condition: '&&'
              },
            ],
          };

          const publicDrugsRes = await this.$api.article.query2(PublicDrugsParams, PublicDrugsData);

          element['PublicDrugs'] = publicDrugsRes.data.result;
          element.maxNumber = publicDrugsRes.data.result[0].maxNumber

          if (element.PublicDrugs.length !== 0) {
            element['imgurl'] = `${base.weburl}static/img/${element.PublicDrugs[0].img_url}`;
          } else {
            element['imgurl'] = '';
          }
        });

        await Promise.all(drugsPromises);

        this.tableData = drugsRes.data.result;
        this.historyTableData = JSON.parse(JSON.stringify(this.tableData))
        let that = this
        setTimeout(function() { 
          that.headershow = false
          console.log(that.headershow)
        }, 5000);
        if(this.search_value !==''){
            let specs = ['全部']
            let manufacturingEnterprises = ['全部']
            this.tableData.forEach(element => {
              specs.push(element.PublicDrugs[0].specs)
              manufacturingEnterprises.push(element.PublicDrugs[0].manufacturingEnterprise)
            });
            let specss=[]
            let manufacturingEnterprise=[]
            Array.from(new Set(specs)).forEach(element => {
              specss.push({text:element,value:element})
            });
            Array.from(new Set(manufacturingEnterprises)).forEach(element => {
              manufacturingEnterprise.push({text:element,value:element})
            });
            this.specss = specss
            this.manufacturingEnterprise = manufacturingEnterprise
            console.log(this.specss)
            console.log(this.manufacturingEnterprise)

       
        }
        this.backups = JSON.parse(JSON.stringify(drugsRes.data.result));

        const prescriptionRes = await this.$api.article.query(
          {
            search_db: 'Pres',
            query_type: 'first',
          },
          {
            query: {
              found_id: localStorage.getItem('userrole_id'),
            },
          }
        );

        if (prescriptionRes.data.result.medicine && prescriptionRes.data.result.medicine !== 'None') {
          let medicine = JSON.parse(prescriptionRes.data.result.medicine);
          let drug_id = medicine.map(element => element.skuId);
          let LastUsedDrugs = []
          drug_id.forEach(element => {
            let params = {
              search_db: 'Drugs',
              query_type: `limit|0-4`,
              no_count:'1'
            };
             let data = {
                query: [
                  {
                    fild: { publicdrugs_id: element },
                    type: '=',
                    condition: '&&',
                  },
                  {
                    fild: { pharmacy_id: this.return_drugs_id() },
                    type: '=',
                    condition: '&&',
                  }
                ],
              };
              this.$api.article.query2(params, data).then(res=>{
                res.data.result.forEach(element => {
                  LastUsedDrugs.push(element)
                });
              })
          });
          this.LastUsedDrugs = LastUsedDrugs
        }
      } catch (error) {
      }
    },
    number_vaule(){
      let  number = 0
      this.tableData.forEach(element => {
        if(element.price !== 'None' && element.number !==0){
          number += element.price*element.number
        }
      });
      return number
    },
    drugs_details(id){
      this.onClickButton()
      this.$router.push({
        name:'drugs_details',
        query:{
          id:id,
        }
      })
    },
    changeSpecs(key){
      // console.log(this[key])
      if(this.specsValue == '' && this.manufacturingEnterpriseValue == ''){
        return
      }
      let tableData = []
      this.historyTableData.forEach(element => {
        if(element.PublicDrugs[0].specs == (this.specsValue == '' || this.specsValue == '全部' ? element.PublicDrugs[0].specs : this.specsValue) && element.PublicDrugs[0].manufacturingEnterprise  == (this.manufacturingEnterpriseValue == ''  || this.manufacturingEnterpriseValue == '全部'? element.PublicDrugs[0].manufacturingEnterprise : this.manufacturingEnterpriseValue)){
          tableData.push(element)
        }
      });
      this.tableData = tableData
    },
    debounce(delay) {
      let that = this
      let timeoutId; // 定时器ID用于清除定时器
    
        clearTimeout(timeoutId); // 清除已有的定时器
        const context = this;
        const args = arguments;
        // 设置一个新的定时器，在指定的延迟时间后执行原函数
        timeoutId = setTimeout(function() {
          that.loadList()
        }, delay);
    }
  },
  computed:{
    hasParameters(imageUrl){
      return function (url) {
      return /img\/(.+)/.test(url);
    };
    }
  },
  created() {
    this.state = this.$route.query.type
    this.disease = JSON.parse(sessionStorage.getItem('disease'))
    this.loadList()
  },
  mounted(){
 

  }
}
</script>
<style  lang="less">
.container {
  display: grid;
  grid-template-rows: auto 1fr auto; /* 自动高度、占满剩余空间、自动高度 */
  height: 100%;
  .Drugs_middle{
    overflow-y: auto;
  }
}

.van-dropdown-menu{
  height: 40px !important;
  border-radius: 3px;
}
.van-popup--top{
  width: 95% !important;
  left: 2.5% !important;
}
.label{
  font-weight: 600;
  color: #F56C6C;
}
.yaopinxuanzhe .van-submit-bar .van-button--block{
  width: auto;
}
.footer_number{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}
.yaopinxuanzhe .el-tag{
  margin: 2px;
}
.ShoppingCart{
  position: fixed;
  bottom: 64px;
  right: 10px;
}
.price{
  color: #F56C6C;
  font-size: 20px;
  font-weight: 600;
}
.van-multi-ellipsis--l1, .van-multi-ellipsis--l2, .van-multi-ellipsis--l3{
  overflow:visible !important;
  text-overflow:clip !important;
  -webkit-line-clamp:999 !important;
}
.van-text-box__title {
  font-weight: 500;
  font-size: 15px !important;
}
</style>
<style lang="less" scoped>
.el-button.is-circle{
  z-index: 999;
}
#app{
  overflow-y:hidden;
}
#Interrogation{
  overflow-y:hidden;
}
.van-submit-bar__wrapper_text{
  position: absolute;
  top: 46px;
  left: 14px;
  font-size: 10px;
}
.drugs_img{
  width: 130px;
  img{
    width: 100%;
  }
}
.van-submit-bar__wrapper_text{
  position: absolute;
  top: 110px;
  left: 14px;
  font-size: 10px;
}
</style>
<style>
.price_number{
  width:100%;
  display:flex;
  justify-content:space-between;
}
</style>
