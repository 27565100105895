<template>
    <div class="bbb Drugs">
        <van-nav-bar title="统筹问诊申请" left-arrow @click-left="onClickLeft"/>
        <div id="Interrogation" class="Drugs time_slot">
            <!-- 就诊人 -->
            <div class="jiuzhenren bpd" style="position: relative;">
                <div class="title">
                    <span class="title_icon">| </span>
                    <span class="DrugsTitle">
                        就诊人信息
                        <span :class="patient.name == '' ? 'required' : 'required_success'">（必填）</span>
                    </span>
                </div>
                <div class="jiuzhenren_xuanzhe margin_top"  style="display: flex;justify-content: space-between; align-items: center;padding-left: 10px; margin-top: 0.5rem !important;">
                    <span class="patient-text">{{ patient.name }} </span>
                    <span v-if="patient.PresCheck" class="required_success"> <i class="el-icon-check"></i> 已授权 </span>
                    <el-button v-else class="color_Danger" @click="AuthPresUrl" icon="el-icon-paperclip" type="text">请进行医保授权</el-button>
                </div>
            </div>


            <div class=" margin_top bpd" style="height: auto;" v-if="patient.PresCheck && Achives.length !==0">
                <div class="title Drugs_title">
                    <div class="DrugsTitle">
                        <span class="title_icon">| </span>
                        就诊人档案
                    </div>
                  
                </div>
                <van-swipe :autoplay="0" :height="273" @change="PatientFilesonChange">
                    <van-swipe-item v-for="(item,index) of Achives" >
                        <el-card :body-style="{ padding: '0px' }" @click.native="ImagePreviewFun(item)">
                            <img :src="item.pres_info.UrlImgs[0]" class="image">
                            <div style="padding: 14px;">
                                <span>{{ item.pres_info.hospital_name }}</span>
                                <div class="bottom clearfix">
                                <time class="time">{{ item.pres_info.patient_disease.join(',') }}</time>
                                <!-- <el-popconfirm
                                    @confirm.native.stop="console.log(321)"
                                    title="您确定删除此档案吗？"
                                >
                                    <el-button type="text" class="button" slot="reference">删除档案</el-button>
                                </el-popconfirm> -->
                                <el-button type="text" @click.stop="deleteAchives(item)" class="button">删除档案</el-button>
                                <el-button type="text" @click.stop="selectPatientFiles" class="button">选择档案</el-button>
                                </div>
                            </div>
                            </el-card>

                        
                
                        <!-- <div class="flex juc">
                            <van-person-card
                            :sub-title="item.pres_info.hospital_name"
                            tag-type="warning"
                            :avatar-figure="item.pres_info.UrlImgs[0]"
                            :content="item.pres_info.patient_disease"
                            />
                            <div>
                                <el-button  type="primary" @click="selectPatientFiles" class="selectPatientFilesBtn" plain>选择档案</el-button>
                                <el-popconfirm
                             
                                    title="这是一段内容确定删除吗？"
                                >
                                    <el-button slot="reference">删除</el-button>
                                </el-popconfirm>
                            </div>
                            

                        </div> -->
                    </van-swipe-item>
                </van-swipe>
            </div>
            <!-- 药品选择 -->
            <div class="yyxz margin_top bpd" style="height: auto;">
                <div class="title Drugs_title">
                    <div class="DrugsTitle">
                        <span class="title_icon">| </span>
                        历史用药<span :class="Drugs.length == 0 ? 'required' : 'required_success'">（必填）</span>
                    </div>
                    <van-button v-if="Drugs.length !==0"  icon='disease-bank' @click="drugsShow = true" size="mini">药品详情</van-button>
                </div>
                <div class='yyxz_btn'>
                    <van-button  icon='report-query' @click="yaopionxuanze('Drugs')" size="mini">药品录入</van-button>
                </div>
                <div class="title bpd text" v-for="(item,index) of Drugs" style="padding-bottom: 5px; padding-left: 16px;">
                    <div>{{ item.name }} <span style="color:#000"> × {{ item.number }}</span></div>
                </div>
            </div>
            

            <!--  -->
            <div class="jjxx  margin_top bpd">
                <div class="title ">
                    <span class="title_icon">| </span>
                    <span class="DrugsTitle">
                        就诊信息
                    </span>
                </div>
                <van-cell-group @click.stop text style="padding: 0 3px; position: relative;">
                    <van-cell value-class="flex-align-end" @click="disease('disease')">
                        <template slot="title">
                            <div class="flex-align">
                                <span >确诊疾病 </span>
                                <span 
                                ><span :class="diseaseList.length == 0 ? 'required' : 'required_success'">（必填）</span></span
                                >
                            </div>
                        </template>
                        <template>
                        </template>
                        <template>
                            <van-icon name="arrow" />
                        </template>
                    </van-cell>
                    <div style=" position: relative; " class="yaowu">
                        <div class="title bpd Current-text" v-for="(item,index) of diseaseList"  style="padding-bottom: 5px;">
                            <div style="padding-left: 4px;">{{ item.title }}</div>
                        </div>
                        <van-cell
                            v-if="diseaseList.length == 0"
                            cell-class="margin-top12 van-bgcolor-background-color"
                            value-class="line-height32 text-align-center"
                            :border="false"
                        >
                            <template>
                                <span class="font-size0 van-color-text-secondary">请选择确诊疾病</span>
                            </template>
                        </van-cell>
                        
                    </div>
                    <div v-if="CheckInquiry.suggest !== ''" class="CheckInquiry" :class="CheckInquiry.suggest == '通过' ? 'agree': 'refuse'">
                        <span>{{ CheckInquiry.suggest + '：' }}</span>
                        <span>{{ CheckInquiry.title }}</span>
                    </div>
                    <van-cell value-class="flex-align-end" >
                        <template slot="title">
                            <div class="flex-align">
                                <span >当前病情描述 </span>
                                <span 
                                ><span :class="conditions == '' ? 'required' : 'required_success'">（必填）</span></span
                                >
                            </div>
                        </template>
                    </van-cell>
                    <van-field
                        type="textarea"
                        v-model="conditions"
                        @input="conditions = conditions.replace(regStr,'')"
                        placeholder="发病时间、主要症状、治疗过程、目前状况等.如用药后有不良反应请说明"
                        rows="2"
                        autosize
                        maxlength="200"
                        show-word-limit
                    />
                    <div class="symptom_auxiliary" v-if="symptomList.length !== 0">
                        我有点：<el-tag
                        v-for="tag in symptomList"
                        :key="tag"
                        @click="symptomCli(tag)"
                        :type="symptomType(tag)"
                        >
                        {{tag}}
                        </el-tag>
                    </div>


                    <!-- 复诊证明 -->

                    <van-cell value-class="flex-align-end"  >
                    <template slot="title">
                        <div class="flex-align">
                            <span >复诊证明 （单张图片限制大小3M）</span>
                            <span >
                                <!-- v-if="!ifOtc()" -->
                                <span  :class="presDownUrl.length == 0 ? 'required' : 'required_success'">（必填）</span>
                                <!-- <span v-else class="required_success">（均为OTC药品，拍药盒上传即可）</span> -->
                                
                            </span>
                        </div>
                    </template>
                    </van-cell>
                    <van-uploader   :max-count="4" class="margin_top" v-model="presImgUrl" multiple :after-read="afterRead" :before-read="beforeRead"  @delete="imgdelete">
                    </van-uploader >
                    <p  class="text pading10 " style="line-height: 22px;">上传化验单、检查资料、问诊单、患处照片或其他疾病描述相关资料(最多4张)</p>
                    <!--  -->

    
                    <van-cell value-class="flex-align-end" >
                        <template slot="title">
                            <div class="flex-align">
                                <span >首诊/线下就诊医院名称 </span>
                                <span ><span :class="firstMedinsName.length > 3 ? 'required_success' : 'required'">（必填）</span></span>
                            </div>
                        </template>
                    </van-cell>
                    <van-field class="firstMedinsName" v-model="firstMedinsName" label-align="left" placeholder="请输入首诊/线下就诊医院名称" />
                    <!-- 过往疾病史 -->
                    <van-cell-group>
                        <van-cell title="过往疾病史" @click="disease('past')" is-link :value="diseasefun('past')"/>
                    </van-cell-group>
                </van-cell-group>
            </div>

            <!--   其他 -->
            <div class="yyxz margin_top bpd" style="height: auto;">
            <div class="title Drugs_title">
                <div class="DrugsTitle">
                    <span class="title_icon">| </span>
                    其他
                </div>
            </div>
            <div >
                <el-checkbox-group class=" pading10" v-model="PresFild">
                    <el-checkbox label="已育"></el-checkbox>
                    <el-checkbox label="未育"></el-checkbox>
                    <el-checkbox label="预备1年内育儿"></el-checkbox>
                    <el-checkbox label="患有高血压"></el-checkbox>
                    <el-checkbox label="患有糖尿病"></el-checkbox>
                    <el-checkbox label="抽烟"></el-checkbox>
                    <el-checkbox label="喝酒"></el-checkbox>
                    <el-checkbox label="血脂异常"></el-checkbox>
                </el-checkbox-group>
                <van-cell-group>
                <van-cell title="家族疾病史" @click="disease('family')" is-link :value="diseasefun('family')"/>
                <van-cell-group>
                <van-field
                    type="textarea"
                    v-model="allergy"
                    label="过敏情况"
                    placeholder="请输入过敏情况"
                    rows="2"
                    autosize
                    maxlength="50"
                    show-word-limit
                />
                </van-cell-group>

            </van-cell-group>
            </div>
            </div>


            <van-checkbox v-model="re_diagnosis" icon-size="25px" class="consent margin_top padding-l-20">
                <span class="consent text  ">我是</span>
                <span @click="illustratedShow = true" class="text consent_color">复诊</span>
            </van-checkbox>
            <van-checkbox v-model="pastHistory" icon-size="25px" class="consent margin_top padding-l-20">
                <span class="consent text  ">有</span>
                <span   class=" pastHistorytext">既往史</span>
            </van-checkbox>
            <van-checkbox v-model="checked" icon-size="25px" class="consent margin_top padding-l-20">
                <span class="consent text  ">我已阅读</span>
                <span @click="InformedShow = true" class="text consent_color"> 知情同意书</span>
            </van-checkbox>
            <div class="jjxx  margin_top  Drugs_btn_mar">
                <van-button color="#02d4d8" @click="CheckInquiry_get" block>开始申请问诊</van-button>
            </div>
        </div>

        <van-popup v-model="illustratedShow"  position="top" closeable :overlay="true">
            <div>
                <illustrated  />
                <div class="m-top">
                    <van-button @click="illustratedShow = false" color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>返 回</van-button>
                </div>
            </div>
        </van-popup>

        <van-popup v-model="InformedShow"  position="top" closeable :overlay="true">
            <div>
                <Informed  />
                <div class="m-top">
                    <van-button @click="InformedShow = false" color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>返 回</van-button>
                </div>
            </div>
        </van-popup>
       





        <!-- 药品详情 -->
        <van-action-sheet v-model="drugsShow" title="已选择历史药品" @cancel="ScreeningDrugs" @click-overlay="ScreeningDrugs">
            <div v-for="(item,index) of Drugs" class="bpd margin_top" >
                <div style="display: flex;" >
                    <div style="width:100px">
                        <van-image v-if="hasParameters(item.imgurl)" width="100" height="100" :src="item.imgurl" />
                        <img v-else style="width:90px;height: 80px ;" src="../../assets/image/Drugs.webp" alt="">
                    </div>
                    <div style="width: 0; flex-grow: 1;">
                        <div style="font-size: 16px; padding:1px 5px;">
                            {{item.name}}
                        </div>
                        <div  style="font-size: 16px; padding:1px 5px;">
                            规格：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].specs : '' }}
                        </div>
                        <div  style="font-size: 12px; padding:1px 5px;">
                            生产企业：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].manufacturingEnterprise : '' }} 
                        </div>
                        <div  style="font-size: 12px; padding:1px 5px;">
                            国药准字：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].approvalNumber : '' }} 
                        </div>
                        <div  style="font-size: 12px; padding:1px 5px;">
                            医保编码：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].drugcode : '' }} 
                        </div>
                        <div class="price_number">
                            <span v-if="item.price !== '0'" class="margin-left4 price">￥{{ item.price == 'None' ? 0  : item.price * item.number}}</span>
                            <div @click.stop style="padding:0 10px;">
                                <van-stepper input-width="20px" button-size="20px" v-model="item.number"  min="0"   :max="item.maxNumber"/>
                                <van-button type="danger" @click="drugsdefault(item,index)" size="mini">删除</van-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-action-sheet>


        <el-drawer
            :visible.sync="Presdrawer"
            direction="btt"
            :show-close="true"
            :wrapperClosable="false"
        >
            <div class="textCenter" v-if="activeName == '0'">
                <p class="text-style margin-top10">正在连线快诊医生接诊<i class="el-icon-loading"></i></p>
                <p class="margin-top10">快诊医生可以更快的为您接诊和问诊</p>
                <el-progress :format="format" class="margin-top10" type="circle" :text-inside="false" :percentage="ProgressPres" ></el-progress>
                <!-- <p class="margin-top10 border" @click="activeName = '3';">不连线了，等待医院排班医生</p> -->
            </div>
            <div class="textCenter" v-if="activeName == '2'">
                <p class="text-style margin-top10">快诊医生</p>
                <p class="margin-top10">快诊医生可以更快的为您接诊和问诊</p>
                <p class="margin-top10 PresbtnFontSize">
                    <el-button @click="afterPres " icon="el-icon-notebook-2"  type="primary">继续等待快诊医生</el-button>
                </p>
                <!-- <p class="margin-top10" >{{reckonbytime}}秒后自动选择去医院排队</p>   -->
            </div>
            <div class="textCenter" v-if="activeName == '3'">
                <p class="text-style margin-top10" v-if="PresInfo.doctorId !== 'None'">
                    {{`快诊医生已接诊`}}
                </p>
                <p v-else class="text-style margin-top10" >
                    {{`由医院安排医生接诊`  }}
                </p>
                <p class="margin-top10">请支付订单后马上和医生沟通</p>
                <p class="margin-top10 Pres0btn">
                    <el-button @click="YBPayImg(PresInfo.pres_id)" v-if="PresInfo.pres_id !=='None'"  icon="el-icon-bank-card" type="primary"> 支付诊费</el-button>
                    <el-button @click="pay_action" v-else icon="el-icon-bank-card"   type="primary">   {{ PresInfo.pres_id !=='None' ? '支付诊费' : '申请问诊' }}</el-button>
                </p>
            </div>
        </el-drawer>
        <footer_/>
    </div>
</template>
<script>
    import footer_ from '../../components/footer.vue'
    import base from '.././api/base.js'
    import illustrated from '../components/illustrated'
    import Informed from '../components/Informed'
    import mixin from "@/utils/mixin";
    import img from '../../assets/js/img.js'
    import {compressImage} from "../../assets/js/CompressImageUtils.js";
    import { ImagePreview } from 'vant-green';
    export default{
        mixins: [mixin],
        components: {
            footer_,
            illustrated,
            Informed
        },
        data(){
            return {
                regStr:/[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi,
                Drugs:[],
                drugsShow:false,
                patient:{
                    id:'',
                    name:'',
                    PresCheck:false,
                    
                },
                firstMedinsName:'',
                diseaseList:[],
                CheckInquiry:{
                    suggest:'',
                    title:''
                },

                conditions:'', //描述
                symptomList:[],
                symptomSelected:[],
                PatientFilesonIndex:0,

                presDownUrl:[], // 复诊证明
                presImgUrl:[],


                past:[],
                family:[],


                PresFild:[],

                PatientFiles:{
                    pres_infoArray:[]
                },

                allergy:'',

                re_diagnosis:false,
                pastHistory:false,
                checked:false,
                illustratedShow:false,
                PharmacyInfo:{},
                price:0,
                Presdrawer:false,
                activeName:'0',
                ProgressPres:0,
                InformedShow:false,



                Achives:[],
                consultType:1
            }
        },
        methods:{
            ImagePreviewFun(item){
                ImagePreview(item.pres_info.UrlImgs)
            },
            deleteAchives(item){
                this.$confirm('是否确认删除此档案, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.Delete('Achives','id',item.id,{deleted:1})
                    })
            },
            ScreeningDrugs(){
                let Drugs = this.Drugs.filter(item=>{return item.number>0})
                this.Drugs = Drugs
            },
            PatientFilesonChange(index){
                this.PatientFilesonIndex = index
            },
            selectPatientFiles(){
                let item = this.Achives[this.PatientFilesonIndex]
                this.presDownUrl = item.pres_info.imgs
                        this.presImgUrl = item.pres_info.UrlImgs.map(element=>{
                            return {content:element,status:null,file:{},url:element }
                        })
                        this.firstMedinsName = item.pres_info.hospital_name ? item.pres_info.hospital_name : ''
                        this.$message({
                            message: '选择成功',
                            type: 'success'
                        });
              
           
            },
            async beforeRead(file){
                if(this.presDownUrl.length >=4){
                    this.$message({
                        message: '最多上传4张复诊证明',
                        type: 'warning',
                    });
                    return false
                }


                return true
            },
            AuthPresUrl(){
                this.$api.article.InternetHospitalGet(
                    {
                        search_db:'AuthPresUrl',
                        ih_type:localStorage.getItem('ih_type')
                    },
                    {
                        user_id:JSON.parse(localStorage.getItem('user')).user_id,
                    }
                ).then(res=>{
                    const h = this.$createElement;
                    this.$msgbox({
                        title: '消息',
                        message: h('p', { style: 'text-align: center' }, [
                            h('span', null, '当前就诊人尚未获得医保授权'),
                            h('br'),
                            h('i', { style: 'font-weight: 800' }, '请点击“授权”按钮进行授权'),
                            h('br'),
                            h('i', null, '授权完成后，'),
                            h('i', { style: 'font-weight: 400' }, '请再次进入此页面')
                        ]),
                        showCancelButton: true,
                        confirmButtonText: '授权',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                        if(action == 'confirm'){
                            window.location.href= res.data.result.data.presUrl
                        }else{
                            done();
                        }
                        }
                    })
                    
                })
            },
            //申请处方
            async CheckInquiry_get(){
                if(this.patient.PresCheck == false){
                    this.$message({
                        message: '请授权就诊人',
                        type: 'warning'
                    });
                    return
                }
                console.log(this.Drugs)
                if(this.Drugs.length ==0){
                this.$message({
                    message: '请选择药品',
                    type: 'warning'
                });
                return
                }
          
            if(this.diseaseList.length ==0){
                this.$message({
                    message: '请选择疾病',
                    type: 'warning'
                });
                return
            }
            if(this.conditions == ''){
                this.$message({
                    message: '请输入病情描述',
                    type: 'warning'
                });
                return
            }
            if(this.presDownUrl.length == 0){
                    this.$message({
                        message: '请上传复诊证明文件',
                        type: 'warning'
                    });
                    return 
            }


            
            if(this.firstMedinsName<4){
                this.$message({
                    message: '请输入首诊/线下就诊医院',
                    type: 'warning'
                });
                return 
            }
            if(!this.re_diagnosis){
                this.$message({
                    message: '请确保您是复诊',
                    type: 'warning'
                });
                return 
            }
            if(!this.pastHistory){
                this.$message({
                    message: '请确保您是有既往史',
                    type: 'warning'
                });
                return 
            }
            if(!this.checked){
                this.$message({
                    message: '请勾选我已阅读知情同意书',
                    type: 'warning'
                });
                return 
            }
            let Drugs = []
            this.Drugs.forEach(element => {
                Drugs.push(element.PublicDrugs[0].id)
                
            });
            let RecentDrugs =  await this.$api.article.RecentDrugs({drugs:Drugs})
            if(RecentDrugs.data.code == '-2'){
                return
            }
            for(let i = 0; i<this.presDownUrl.length;i++){
                let inspectProve = await this.$api.article.img_info(this.presDownUrl[i],false) 
                if(inspectProve.data.code == '-2'){
                    return
                }
            }
            const h = this.$createElement;
            let messageList = [ ]
            this.Drugs.forEach(element => {
                messageList.push(
                    h('i', { style: 'font-weight: 800' },`${element.name} × ${element.number}`  )
                    
                )
                messageList.push(
                    h('br'),
                )
            });
            this.$msgbox({
                title: '您当前选择的历史用药为',
                message: h('p', { style: 'text-align: center' }, messageList),
                showCancelButton: true,
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if(action == 'confirm'){
                        const loading = this.$loading({
                            lock: true,//lock的修改符--默认是false
                            text: '正在为您的问诊进行用药检查',//显示在加载图标下方的加载文案
                            color:'rgba(255, 255, 255, 1)',
                            spinner: 'el-icon-loading',//自定义加载图标类名
                            background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
                            target: document.querySelector('#table')//loadin覆盖的dom元素节点
                        });
                        setTimeout(() => {
                            loading.close();
                        }, 10000);
                        this.postPres()
                    }
                    done();
                    
                }
            })
            },
            //创建 处方
            async postPres(){
                
                let medicine = []
                let DRGDatabase_name = []
                this.diseaseList.forEach(element => {
                    DRGDatabase_name.push({name:element.title})
                });
                let Drugs = this.Drugs
                if(Drugs.every(medicine => medicine.PublicDrugs[0].class_ab === '丙类')){
                    this.$message({
                        message: '您申请的药品，均不能参与报销，故不需要申请统筹报销。',
                        type: 'success'
                    });
                    return
                }
                Drugs.forEach(element => {
                    medicine.push(
                        {
                            skuId:element.PublicDrugs[0].id,
                            total:`${element.number}`
                        }
                    )
                });
                let texts = [
                    `医生你好，我是${this.patient.name}，`,
                    // `医生你好，我是${this.patient.name}， 性别：${this.patient.sex == 1 ? '男' : '女'}，年龄：${this.patient.age}，`,
                    // `性别：${this.patient.sex == 1 ? '男' : '女'}`,
                    // `年龄：${this.patient.age}，`,
                    // `我的历史用药为：${this.returnStr(this.Drugs,'name',true)}，`,
                    // `确诊的疾病为：${this.returnStr(this.diseaseList,'title')}，`,
                    // `当前病情为：${this.conditions.replace('我有点','')}`,
                    // `过往的疾病史为：${  this.returnStr(this.past,'title')  }，`,
                    // `过敏情况为：${this.allergy == '' ? '无' : this.allergy}，我是复诊，请尽快帮我开问诊，谢谢`,
                    // `我是复诊`,
                    `请尽快帮我开问诊，谢谢`,
                ]
                let pres_body = {
                    presInfo:this.pres_body,
                    Drugs:this.DrugsSieveRemoval(this.Drugs),
                    Current:this.Current,
                    diseaseList:this.diseaseList,
                    past:this.past,
                    allergy:this.allergy,
                    family:this.family,
                    PresFild:this.PresFild,
                    patient:this.patient,
                    text:JSON.stringify(texts)
                }
                console.log(this.patient)
            let data = {
                    created:{
                            ih_type:localStorage.getItem('ih_type'),
                            patientId:this.patient.id,
                            consultType:this.consultType,
                            price:this.price,
                            conditions:this.conditions,
                            historyContent:'无',
                            allergyContent:'无',
                            birthContent:'无',
                            isSmoking:this.PresFild.indexOf('吸烟') == -1 ? 0 : 1,
                            isDrink:this.PresFild.indexOf('喝酒') == -1 ? 0 : 1,
                            isFeritin:this.PresFild.indexOf('患有高血压') == -1 ? 0 : 1,
                            isDislipidemia:this.PresFild.indexOf('血脂异常') == -1 ? 0 : 1,
                            isDiabetes:this.PresFild.indexOf('患有糖尿病') == -1 ? 0 : 1,
                            firstMedinsName:this.firstMedinsName,
                            imgs:JSON.stringify(this.presDownUrl),
                            familyhis:'无',
                            pres_body:JSON.stringify(pres_body),
                            medicine:JSON.stringify(medicine),
                            pharmacy_id:this.PharmacyInfo.id,
                            if_dispatch:1,
                            DRGDatabase_name:JSON.stringify(DRGDatabase_name) 
                        }
                }
                
                this.post_pres(data,'afterPres')
            },
            post_pres(data,funName){
                const loading = this.$loading({
                    lock: true,//lock的修改符--默认是false
                    text: '正在为您选择更快的互联网医院为您申请问诊',//显示在加载图标下方的加载文案
                    color:'rgba(255, 255, 255, 1)',
                    spinner: 'el-icon-loading',//自定义加载图标类名
                    background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
                    target: document.querySelector('#table')//loadin覆盖的dom元素节点
                });
                setTimeout(() => {
                    loading.close();
                }, 10000);
                setTimeout(() => {
                    let params = {
                    search_db:'Pres',
                }
                    this.$api.article.created(params,data).then(res=>{
                        loading.close();
                        if(res.data.code && res.data.code == '200'){
                            this.PresInfo = res.data.result
                            this[funName]()
                            
                        }else{
                            this.$message('开具问诊失败！');
                        }
                    })
                }, 1000);
            
            },
            afterPres(){
                this.Presdrawer = true
                let that = this
                this.activeName = '0'
                this.ProgressPres = 100
                let timeSetInterval = 5
                clearInterval(that.timeSetInterval)
                this.timeSetInterval = setInterval(function(res) {
                    timeSetInterval = timeSetInterval - 1
                    if(that.activeName != '0'){
                        clearInterval(that.timeSetInterval)
                    }
                    if(that.ProgressPres ==0){
                        clearInterval(that.timeSetInterval)
                        that.afterWait()
                    }else{
                        that.ProgressPres = that.ProgressPres - 4
                        that.apireturn('Pres','id',that.PresInfo.id).then(res=>{
                            that.PresInfo = res.data.result
                            if(res.data.result.doctorId !=='None'){
                                that.activeName = '3'
                                that.pay_action(false)
                                clearInterval(that.timeSetInterval)
                            }else{
                                that.$api.article.get_doctor(res.data.result.id)
                            }
                            if(timeSetInterval == 0){
                                that.$api.article.get_doctor(res.data.result.id)
                            }
                        })  
                    }
                }, 1000);
            },
            afterWait(){
                this.activeName = '2'
                let that = this;
                this.reckonbytime = 10
                this.timeSetInterval = setInterval(function(res) {
                    if(that.activeName !='2'){
                        clearInterval(that.timeSetInterval)
                    }
                    if(that.reckonbytime ==0){
                        that.activeName = '3'
                        clearInterval(that.timeSetInterval)
                    }else{
                        that.reckonbytime = that.reckonbytime - 1
                    }
                }, 1000);

            },
            pay_action(JumpState = true){
                this.loading = this.$loading({
                    lock: true,//lock的修改符--默认是false
                    text: '正在为您申请问诊',    //显示在加载图标下方的加载文案
                    color:'rgba(255, 255, 255, 1)',
                    spinner: 'el-icon-loading',//自定义加载图标类名
                    background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
                    target: document.querySelector('#table')//loadin覆盖的dom元素节点
                });
                if(this.PresInfo.doctorId !== 'None'){  //医生接单
                    this.created_order(this.PresInfo.doctorId,this.PresInfo.id,JumpState)
                }else{
                    this.apireturn('Pres','id',this.PresInfo.id).then(res=>{
                        this.PresInfo = res.data.result
                        this.loading.close();
                        if(res.data.result.doctorId =='None'){
                            this.$api.article.get_doctor(this.PresInfo.id).then(res=>{
                                if(Array.isArray(res.data.result) && res.data.result.length !==0){
                                    this.doctorInfo = res.data.result[0]
                                    this.created_order(this.doctorInfo.id,this.PresInfo.id)
                                    
                                }else{
                                    this.loading.close();
                                    this.$confirm('当前暂无可用医生, 是否继续?', '提示', {
                                        confirmButtonText: '继续等待',
                                        cancelButtonText: '取消等待',
                                        type: 'warning'
                                        }).then(() => {
                                            this.afterPres()
                                        }).catch(() => {
                                                
                                        });
                                }
                            })
                        }else{
                            this.created_order(this.PresInfo.doctorId,this.PresInfo.id)
                        }
                    })  
                }
            },
            format(percentage){
                return percentage/4 + '秒';
            },
            onClickLeft(){
                sessionStorage.removeItem('Drugs');
                sessionStorage.removeItem('Current');
                sessionStorage.removeItem('disease');
                sessionStorage.removeItem('post_pres_info');
                this.$router.back(-1)
            },
            yaopionxuanze(type){
                this.$router.push({
                    name:'yaopinxuanzhe',
                    query:{
                        type:type,
                    }
                })
                sessionStorage.setItem('Drugs',JSON.stringify(this.Drugs))
                sessionStorage.setItem('post_pres_info',JSON.stringify(
                    {
                        presDownUrl:this.presDownUrl,
                        presImgUrl:this.presImgUrl,
                        firstMedinsName:this.firstMedinsName
                    }
                ))

           
            },
            loadList() {
                this.loading = this.$loading({
                    lock: true,
                    text: '正在为你选择更快的互联网医院',
                    color: 'rgba(255, 255, 255, 1)',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                    target: document.querySelector('#table')
                });

                this.$api.article.InternetHospitalGet(
                    {
                        search_db: 'Patient',
                        ih_type: localStorage.getItem('ih_type')
                    },
                    {
                        user_id: JSON.parse(localStorage.getItem('user')).user_id,
                    }
                ).then(res => {
                    this.loading.close();
                    if (res.data.result.data) {
                        this.patient = {
                            id: res.data.result.data.id,
                            name: res.data.result.data.name,
                            sex: res.data.result.data.sex,
                            age: res.data.result.data.age,
                            PresCheck: res.data.result.data.PresCheck
                        };
                        if (res.data.result.data.PresCheck) {
                            this.$api.article.query2(
                                {
                                    search_db: 'Achives',
                                    query_type: 'equal'
                                },
                                {
                                    query: [
                                        {
                                            fild: {
                                                found_id: this.patient.id,
                                                name: this.patient.name,
                                                project_share: JSON.parse(localStorage.getItem('PharmacyInfo')).project_id,
                                                state: 'disable',
                                            },
                                            type: '=',
                                            condition: '&&',
                                        },
                                    ]
                                }
                            ).then(res => {
                                let Achives = res.data.result;
                                let that = this;
                                if (Achives.length !== 0) {
                                    this.apireturn('Project', 'id', JSON.parse(localStorage.getItem('PharmacyInfo')).project_id).then(res => {
                                        let ProjectInfo = res.data.result;
                                        this.$confirm(`您在${ProjectInfo.title}诊所就诊过,存在${Achives.length}条就诊记录，是否授权尚云方使用`, '提示', {
                                            distinguishCancelAndClose: true,
                                            confirmButtonText: '授权',
                                            cancelButtonText: '取消'
                                        })
                                        .then(async () => {
                                            // 创建更新请求的数组
                                            const updatePromises = Achives.map(element => {
                                                let params = {
                                                    search_db: 'Achives',
                                                    query_type: 'equal'
                                                };
                                                let data = {
                                                    query: {
                                                        id: element.id
                                                    },
                                                    update: {
                                                        state: 'enable'
                                                    }
                                                };
                                                return this.$api.article.update(params, data);
                                            });

                                            try {
                                                // 等待所有请求完成
                                                await Promise.all(updatePromises);
                                                this.getAchives(that.patient.id, that.patient.name);
                                            } catch (error) {
                                                console.error('更新失败', error);
                                            }
                                        })
                                        .catch(action => {
                                            this.getAchives(that.patient.id, that.patient.name);
                                        });
                                    });
                                } else {
                                    this.getAchives(that.patient.id, that.patient.name);
                                }
                            });
                        }
                    }
                });
            },

            getAchives(patient_id,name){
                this.$api.article.query2(
                                {
                                    search_db:'Achives',
                                    query_type:'equal'
                                },
                                {
                                    query:[
                                        {
                                            fild: { 
                                                found_id:this.patient.id,
                                                name:name,
                                                project_share:JSON.parse(localStorage.getItem('PharmacyInfo')).project_id,
                                                state:'enable'
                                            },
                                            type: '=',
                                            condition: '&&',
                                        },
                                    ]
                                }
                ).then(res=>{
                    let Achives = res.data.result
                    console.log(Achives)
                    Achives.forEach(element => {
                        if(element.pres_info == 'None'){
                            return
                        }
                        let UrlImgs = []
                        element.pres_info = JSON.parse(element.pres_info)
                        element.pres_info.imgs.forEach(item => {
                            UrlImgs.push(
                                `${base.baseUrl}img?static_path=Pres&file_name=${item}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
                            )
                        });
                        element.pres_info['UrlImgs'] = UrlImgs
                    });
                    this.Achives = Achives
                })
            },
            disease(type){
                this.$router.push({
                    name:'disease',
                    query:{
                        type:type,
                    }
                })
                sessionStorage.setItem('Drugs',JSON.stringify(this.Drugs))
                sessionStorage.setItem('post_pres_info',JSON.stringify(
                    {
                        presDownUrl:this.presDownUrl,
                        presImgUrl:this.presImgUrl,
                        firstMedinsName:this.firstMedinsName
                    }
                ))
            },
            getPharmacy(){
                this.$api.article.query({
                    search_db: 'Pharmacy',
                    query_type: 'first',
                }, {
                    query: {
                        id: sessionStorage.getItem('pharmacy_id')
                    },
                }).then(async res => {
                    localStorage.setItem('drugs_id',res.data.result.drugs_id)
                    this.PharmacyInfo = res.data.result
                });
            },
            //更新药品信息
            putInfo(){
                this.Drugs = sessionStorage.getItem('Drugs') ? JSON.parse(sessionStorage.getItem('Drugs')) : []
                let promises = [];
                let DiseaseSymptomDrug = []
                this.price = 0
                this.Drugs.forEach(item => {
                    if(item.price !=='None'){
                        this.price +=item.price*1*item.number
                    }
                    if(item.PublicDrugs.length !==0){
                        item['imgurl'] = `${base.weburl}static/img/${item.PublicDrugs[0].img_url}`
                    }
                    let params = {
                        search_db: 'DiseaseSymptomDrug',
                        query_type: 'like',
                    };

                    let data = {
                        query: {
                            drug: item.name
                        }
                    };

                    let promise = this.$api.article.query(params, data).then(res => {
                        res.data.result.forEach(element => {
                            DiseaseSymptomDrug.push(element);
                        });
                    });

                    promises.push(promise);
                });
                Promise.all(promises).then(() => {
                    let symptomList = []
                    DiseaseSymptomDrug.forEach(element => {
                        element.symptom.split(',').forEach(item => {
                            if(symptomList.indexOf(item) == -1 && symptomList.length<=10){
                                symptomList.push(item)
                            }
                        });
                    });
                    this.symptomList = symptomList
                });
                // 更新疾病信息
                this.diseaseList = sessionStorage.getItem('disease') ? JSON.parse(sessionStorage.getItem('disease')) : []
                this.past = sessionStorage.getItem('past') ? JSON.parse(sessionStorage.getItem('past')) : []
                if(sessionStorage.getItem('post_pres_info')){
                    let { presDownUrl,presImgUrl,firstMedinsName} = JSON.parse(sessionStorage.getItem('post_pres_info'))
                    this.presDownUrl = presDownUrl
                    this.presImgUrl = presImgUrl
                    this.firstMedinsName = firstMedinsName
                }
                

            },
            symptomType(name){
                if(this.symptomSelected.indexOf(name) == -1){
                    return 'success'
                }else{
                    return 'info'
                }
            },
            symptomCli(name){
                if(this.symptomSelected.indexOf(name) == -1){
                    this.symptomSelected.push(name)
                }else{
                    this.symptomSelected.splice(this.symptomSelected.indexOf(name),1)
                }
                this.conditions = this.symptomSelected.length !==0 ? `我有点${this.symptomSelected.toString()}` :''
                this.conditions = this.conditions.replace(/,/g, '，')
            },
            ifOtc(){  //判断是否药品都是otc药品
                return this.Drugs.length == 0 ? false : this.Drugs.every(item=>{return item.PublicDrugs[0].otc == '1'})
            },
            async afterRead(file, detail){
                console.log(file[0].file.size)
                if(file[0].file.size >3072000){
                    for(let i=0;i<file.length;i++){
                        let element = file[i]
                        for(let i = 3000;i>=0;i-=500){
                            let max = i
                            let compressInfo = await compressImage(element.content,max)
                                if(compressInfo.size<3072000){
                                    let Blob = compressInfo
                                    let formData = new FormData();
                                    formData.append('file', Blob,'123456.png');
                                    this.$api.article.upload('Pres',formData).then(res=>{
                                        if(res.data.code == '200'){
                                            this.presDownUrl.push(res.data.result.file_name)
                                            this.img_info(res.data.result.file_name,true)
                                            element.status = null
                                        }
                                    
                                    })
                                    break
                                }
                            }
                    }
                }else{
                    file.forEach(element => {
                        let Blob = this.base64ToBlob(element.content)
                        let formData = new FormData();
                        formData.append('file',Blob,'123456.png');
                        this.$api.article.upload('Pres',formData).then(res=>{
                            if(res.data.code == '200'){
                                this.presDownUrl.push(res.data.result.file_name)
                                this.img_info(res.data.result.file_name,true)
                                element.status = null
                                
                                console.log('无需压缩',)
                            }
                        })
                    });
                }
                
            },
            img_info(file_name,check){
                this.$api.article.img_info(file_name,check)
            },
            base64ToBlob(dataurl) {
                var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            },
            imgdelete(file, detail){
                this.presDownUrl.splice(detail.index,1)
            },
            diseasefun(data){
                let str = ''
                this[data].forEach(element => {
                str+=element.title
                });
                if(str.length >=10){
                    str = this[data][0].title+'...'
                }
                return str
            },
            returnStr(data,key,DrugsState=false){
                let str = ''
                if(data.length == 0){
                    str = '无'
                }
                for (var i = 0; i < data.length; i++) {
                    
                    if(DrugsState){
                        str+=`${data[i][key]}（${data[i].PublicDrugs[0].specs}×${data[i].number}`
                        if(data[i].PublicDrugs[0].dosage_txt !== '' && data[i].PublicDrugs[0].dosage_txt !== 'None'){
                            str+= `用法用量：（${data[i].PublicDrugs[0].dosage_txt }）`
                        }
                        if(data[i].PublicDrugs[0].indication !== '' && data[i].PublicDrugs[0].indication !== 'None'){
                            str+= `,适应症：（${data[i].PublicDrugs[0].indication}）`
                        }
                        
                    }else{
                        str += data[i][key];
                        
                    }
                    if (i < data.length - 1) {
                        str += ',';
                    }
                }
                return str
            },
            DrugsSieveRemoval(Drugs){
                let DrugsS = []
                Drugs.forEach(element => {
                    DrugsS.push(
                        {
                            name:element.name,
                            id:element.id,
                            PublicDrugs:[
                                {
                                    name:element.PublicDrugs[0].name,
                                    id:element.PublicDrugs[0].id,
                                    otc:element.PublicDrugs[0].otc,
                                }
                            ]
                        }
                    )
                });
                return DrugsS
            },
            getGenderFromIdCard(idCard){ //// 判断性别
                var genderDigit = idCard
                // 判断性别
                if (genderDigit % 2 === 0) {
                    return "2";
                } else {
                    return "1";
                }
            },
            //发起互联网医院问诊
            created_order(doctorId,presID,JumpState = true){
                let that = this
                setTimeout(() => {
                    that.loading.close();
                    if(!that.create_pres_success){
                        this.$message.error('申请问诊失败，请重新申请');
                        that.create_pres_success = false
                    }
                } , 50000);
                this.apireturn('Pres','id',presID).then(res=>{
                    if(res.data.result.pres_id == 'None'){
                        let data = {
                            doctor_id:doctorId,
                            pres_id:presID,
                        }
                        const loading = this.$loading({
                            lock: true,//lock的修改符--默认是false
                            text: '加载中...',//显示在加载图标下方的加载文案
                            color:'rgba(255, 255, 255, 1)',
                            spinner: 'el-icon-loading',//自定义加载图标类名
                            background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
                            target: document.querySelector('#table')//loadin覆盖的dom元素节点
                        });
                        setTimeout(() => {
                            loading.close();
                        }, 10000);
                        this.$api.article.CreatedInternetPres(data).then(res=>{ 
                            this.create_pres_success = true
                            loading.close();
                            if(res.data.code != '-2'){
                                this.PresInfo = res.data.result
                                if(JumpState){
                                    this.YBPayImg(res.data.result.pres_id)
                                }else{
                                    this.loading.close();
                                }
                            }else{
                                const h = this.$createElement;
                                this.$msgbox({
                                title: '提示',
                                message: h('p', null, [
                                    h('span', null, '问诊申请不成功,不正常原因为:'),
                                    h('i', { style: 'color: teal' }, `${res.data.text}`)
                                ]),
                                confirmButtonText: '确定',
                                    beforeClose: (action, instance, done) => {
                                        done();
                                        // if(action == 'confirm'){
                                        //     created_order(doctorId,presID,JumpState = true)
                                        // }else{
                                        //     done();
                                        // }
                                    }
                                })
                            }
                        })
                    }else{
                        if(JumpState){
                            this.YBPayImg(res.data.result.pres_id)
                        }else{
                            this.loading.close();
                        }
                    }
                })
                
            },
            // 跳转支付连接
            YBPayImg(pres_id){
                if(this.PresInfo.doctorId == 'None'){
                    this.$message.error('暂无可用医生');
                    return
                }
                let that = this
                setTimeout(() => {
                    that.loading.close();
                } , 10000);
                this.$api.article.YBPayImg(pres_id,1,localStorage.getItem('ih_type')).then(res=>{
                    this.$alert('请支付成功后重新进入问诊', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        sessionStorage.removeItem('Drugs');
                        sessionStorage.removeItem('Current');
                        sessionStorage.removeItem('disease');
                        sessionStorage.removeItem('patientId');
                        if(res.data.result.data.slice(0,4) == 'http'){
                            this.loading.close();
                            window.location.href= res.data.result.data
                        }else{
                            this.loading.close();
                            that.$router.push('/message')
                        }
                    }
                    });
                    
            })
            },
            drugsdefault(item,index){
                this.Drugs.splice(index,1)
                let drugSelected = JSON.parse(sessionStorage.getItem('drugSelected'))
                drugSelected.splice(index,1)
                sessionStorage.setItem('drugSelected', JSON.stringify(drugSelected))
            },
        },
        computed:{
            hasParameters(imageUrl){
                    return function (url) {
                    return /img\/(.+)/.test(url);
                };
            }
        },
        created(){
            this.loadList()
            this.getPharmacy()
            this.putInfo()
            if(this.$route.query.consultType){
                this.consultType = this.$route.query.consultType
            }
            console.log(this.consultType)
            
        }
    }
</script>
<style lang="less">
.Drugs{
    .van-avatar img{
    width:100px !important;
    height: 100px !important
}
.van-person-card__title{
    font-weight: 600;
}
.van-doctor-card:not(:last-child)::after, .van-person-card:not(:last-child)::after{
    display: none
}
    .patient-text{
        font-size: 15px;

    }
    .firstMedinsName .van-field__control--right{
        text-align: left !important;
    }
.selectPatientFilesBtn{
    padding: 12px 10px !important;
    height: 100px;
}
.van-person-card__content-item{
    margin: 0 !important;
}
.juc{
    justify-content: space-between;
    align-items: center;
}
}

</style>
<style scoped>
.time {
    font-size: 13px;
    color: #999;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    height: 200px;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
</style>
