/**
 * 图片压缩工具类
 * 最大高度和最大宽度都为 500，如果超出大小将等比例缩放。
 *
 * 注意可能出现压缩后比原图更大的情况，在调用的地方自己判断大小并决定上传压缩前或压缩后的图到服务器。
 */
// 将base64转换为blob
export function convertBase64UrlToBlob(urlData) {
 let arr = urlData.split(',')
 let mime = arr[0].match(/:(.*?);/)[1]
 let bstr = atob(arr[1])
 let n = bstr.length
 let u8arr = new Uint8Array(n)
 while (n--) {
  u8arr[n] = bstr.charCodeAt(n)
 }
 return new Blob([u8arr], {type: mime})
}

// 压缩图片
export function compressImage(path,max) {
    // 最大高度
    const maxHeight = max;
    // 最大宽度
    const maxWidth = max;
    
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.crossOrigin = 'Anonymous'; // 处理跨域图片
        img.src = path;
        
        img.onload = function () {
            const originWidth = img.width;
            const originHeight = img.height;
            
            let compressedWidth = originWidth;
            let compressedHeight = originHeight;
            
            // 判断是否需要压缩
            if (originWidth > maxWidth || originHeight > maxHeight) {
                // 计算压缩比例
                let ratio = Math.min(maxWidth / originWidth, maxHeight / originHeight);
                compressedWidth = originWidth * ratio;
                compressedHeight = originHeight * ratio;
            }
            
            // 创建canvas
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            canvas.width = compressedWidth;
            canvas.height = compressedHeight;
            
            // 清除画布
            context.clearRect(0, 0, compressedWidth, compressedHeight);
            
            // 绘制压缩后的图像
            context.drawImage(img, 0, 0, compressedWidth, compressedHeight);
            
            // 将canvas转换为blob
            canvas.toBlob(function (blob) {
                resolve(blob);
            }, 'image/*', 1);
        };
        
        img.onerror = function () {
            reject(new Error('Failed to load image'));
        };
    });
}

