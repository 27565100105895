<template>
   <div id="leftNavigationBar">
     <div class="menuBox">
       <el-menu  :default-active="$route.path"  :unique-opened="true" class="el-menu-vertical-demo" text-color="#fff"   :router="true">
        <el-menu-item index="/">
          <i class="el-icon-picture-outline-round"></i>
          <span slot="title">概览预览</span>
        </el-menu-item>
        <el-menu-item index="/Drugs">
          <i class="el-icon-phone-outline"></i>
          <span slot="title">商品管理</span>
        </el-menu-item>
        <el-menu-item index="/prescription">
          <i class="el-icon-office-building"></i>
          <span slot="title">问诊管理</span>
        </el-menu-item>
        <!-- <el-menu-item index="/TargetingAppointmentList" v-if="userpower.indexOf('靶向预约') != -1">
          <i class="el-icon-aim"></i>
          <span slot="title">靶向预约</span>
        </el-menu-item>
        <el-menu-item index="/CallStation" v-if="userpower.indexOf('患者叫号') != -1">
          <i class="el-icon-phone-outline"></i>
          <span slot="title">患者叫号</span>
        </el-menu-item>
        <el-menu-item index="/FeedbackList" v-if="userpower.indexOf('不良反应') != -1">
          <i class="el-icon-school"></i>
          <span slot="title">不良反应</span>
        </el-menu-item>
        <el-menu-item index="/Opinion" v-if="userpower.indexOf('患者随访') != -1">
          <i class="el-icon-document-copy"></i>
          <span slot="title">患者随访</span>
        </el-menu-item>
        <el-menu-item index="/InfoList" v-if="userpower.indexOf('健康宣教') != -1">
          <i class="el-icon-picture-outline"></i>
          <span slot="title">健康宣教</span>
        </el-menu-item>
        <el-submenu index="4" v-if="userpower.indexOf('靶向注射') != -1 || userpower.indexOf('普通注射') != -1 || userpower.indexOf('膀胱灌注') != -1">
          <template slot="title">
            <i class="el-icon-folder-opened"></i>
            <span>治疗管理</span>
          </template>
            <el-menu-item index="/TargetingList" v-if="userpower.indexOf('靶向注射') != -1">靶向注射</el-menu-item>
            <el-menu-item index="/CommonList" v-if="userpower.indexOf('普通注射') != -1">普通注射</el-menu-item>
            <el-menu-item index="/BladderList" v-if="userpower.indexOf('膀胱灌注') != -1">膀胱灌注</el-menu-item>
        </el-submenu>
        <el-submenu index="7" v-if="userpower.indexOf('治疗类型') != -1 || userpower.indexOf('药品类型') != -1 || userpower.indexOf('药品明细') != -1 || userpower.indexOf('预约时间') != -1">
          <template slot="title">
            <i class="el-icon-s-tools"></i>
            <span>平台管理</span>
          </template>
          <el-menu-item index="/InfusionTypeList" v-if="userpower.indexOf('治疗类型') != -1">治疗类型</el-menu-item>
          <el-menu-item index="/LocalPresTypeList" v-if="userpower.indexOf('药品类型') != -1">药品类型</el-menu-item>
          <el-menu-item index="/LocalPresList" v-if="userpower.indexOf('药品明细') != -1">药品明细</el-menu-item>
          <el-menu-item index="/AppointmentperiodList" v-if="userpower.indexOf('预约时间') != -1">预约时间</el-menu-item>
          <el-menu-item index="/SymptomList" v-if="userpower.indexOf('症状管理') != -1">症状管理</el-menu-item>

        </el-submenu> -->
       </el-menu>
     </div>
   </div>
</template>
<script>
export default {
    data(){
      return {
        userpower:[]
      }
    },
    methods:{
      
    },
    created(){
      this.userpower = JSON.parse(localStorage.getItem('userpower')).get
    }

}
</script>