<template>
    <div class="bbb">
        <div class="Informed">
                <h4 style="text-align: center;" class="agreementtitle">复诊说明</h4>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;1、根据《互联网诊疗管理办法（试行）》等国家相关政策法规规定，互联网医院在线问诊仅提供部分适宜的慢病复诊服务。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;2、为保证您个人的生命健康安全，建议您在提交前再次仔细核对所填写病情及历史诊疗信息的真实性、客观性，以免因信息填写错误贻误病情。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;3、问诊药须凭问诊在药师指导下购买和使用，国药医生仅提供信息技术服务。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;4、您成功支付后，如医生长时间未回复或医生审核信息后选择拒诊，系统会在7个工作日内自动退款到您的账户中。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;5、使用医保支付患者，如需退费目前仅支持线下退费。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;6、医生在线诊疗回复仅为医学建议或根据互联网诊疗复诊规范给予的意见，如您为危急重症患者，请及时前往医院线下就诊。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;7、如有任何问题，可联系我们。
                </div>
                <!-- <div class="m-top">
                    <van-button @click="illustratedShow = false" color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>返 回</van-button>
                </div> -->
          
               
            </div>
        
    </div>
  </template>

<script>
     export default {
        data(){
            return {
                illustratedShow:true
            }
        }
     }
</script>
 
